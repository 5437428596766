import React from "react";
import { withRouter } from "react-router";
import './styles.css'
import Select from 'react-select'
import ApiService from "../../../services/ApiService";
import StorageService from "../../../services/StorageService";
import Header from '../../../components/Conference/Header'

const TextField = ({placeholder,onChange,error,value})=>(
    <div className="form-control">
    <input
    defaultValue={value}
    className={error!=null ? 'error' : ''} onChange={onChange} placeholder={placeholder}/>
    {error &&
        <span>{error}</span>
    }
    
</div>
)
class Profile extends React.Component {
    state ={
        name:'',
        phone:'',
        email:'',
        company:'',
        companyName:'',
        image:'',

        nameError:null,
        phoneError:null,
        emailError:null,
        companyError:null,
        companyNameError:null,
        user:{},
        is_new_company:false,
       companies:[ { value: 'others_99', label: 'Others' }],
    };

    componentDidMount(){
       this.getUserDetails();
        this.fetchCompanyList();
    }

    getUserDetails(){
       let user =  JSON.parse(StorageService.getData("user_details"));
       console.log(user);
       if(user){
        this.setState({
         
            name:user.name,
            email:user.email,
            phone: typeof user?.phone =='undefined' ? '' : user.phone,
            company_id:user.company_id,
            image:user.profile_image,
            company:{value:user.company_id,label:''}

        })
       }
      
    }

    fetchCompanyList(){      
        ApiService.getData('getCompanies', [], false).then((response) => {
            if(response.status=='success'){
                let {data} =response;
                this.setState(prevState=>({companies:[...data,...prevState.companies]}))
            }
        })
    }


    _selectCompany(value){
       if(value.value =='others_99'){
        this.setState({is_new_company:true});
       }else{
        this.setState({is_new_company:false});
       }
    }

    render(){
        return(
            <>
                <Header/>
                    <div className="wraper" style={{paddingTop:30}}>
                            <div className="profile-image profile">
                                <img src={this.state.image}/>          
                            </div>
                        </div>
            <div className="container register-form">

                <form>
                    <TextField 
                        placeholder={'Name'}
                        value={this.state.name}
                        onChange={(evt)=>{this.setState({name:evt.target.value,nameError:null})}}
                        error={this.state.nameError}
                    />

                    <TextField 
                        placeholder={'Phone No'}
                        value={this.state.phone}
                        onChange={(evt)=>{this.setState({phone:evt.target.value,phoneError:null})}}
                        error={this.state.phoneError}
                    />

                    <TextField 
                        placeholder={'Email'}
                        value={this.state.email}
                        onChange={(evt)=>{this.setState({email:evt.target.value,emailError:null})}}
                        error={this.state.emailError}
                    />

                    <div className={this.state.companyError ? "select-form select-error" : 'select-form'}>
                        <Select 
                       value = {
                        this.state.companies.filter(option => 
                           option.value === this.state.company_id)
                     }
                        onChange={(value)=>{this._selectCompany(value);this.setState({companyError:null,company:value,company_id:value.value})}}
                        placeholder={'Select Company'} options={this.state.companies} />
                         {this.state.companyError &&
                                <span className="select-error-span">{this.state.companyError}</span>
                            }
                            
                    </div>
                {
                    this.state.is_new_company &&
                    <div style={{marginTop:20}}>
                    <TextField 
                    placeholder={'Enter Your Company Name'}
                    onChange={(evt)=>{this.setState({companyName:evt.target.value,companyNameError:null})}}
                    error={this.state.companyNameError}
                />
                </div>
                }
                   
                    <button className="save-btn" onClick={(event)=>this._handleValidation(event)}>Save</button>
                </form>
            </div>
            </>
        )
    }

    _handleValidation(event){
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


        let error = false;
        if(this.state.name.length < 1){
            error =true;
            this.setState({nameError:'Name Cannot be blank.'});
        }

        if(this.state.phone.length < 1){
            error =true;
            this.setState({phoneError:'Phone Cannot be blank.'});
        } else if(isNaN(parseInt(this.state.phone)) || this.state.phone.length !=10){
            error= true;     
            this.setState({phoneError:'Enter a valid phone number.'});   
        }

        if(this.state.email.length < 1){
            error =true;
            this.setState({emailError:'Email Cannot be blank.'});
        }else if(!this.state.email.match(validRegex)){
            error = true;
            this.setState({emailError:'Enter a valid email address.'});
        }

        if(this.state.company==''){
            error = true;
            this.setState({companyError:'Company cannot be blank.'});
        }
        //Company 
        if(this.state.is_new_company && this.state.companyName.length < 1){
            error = true;
            this.setState({companyNameError:'Company Name cannot be blank.'});
        }
        if(!error){
            this.saveProfile()
        }
        event.preventDefault();
    }

    //Save User Profile
    saveProfile(){
        let postParams = {
            name:this.state.name,
            email:this.state.email,
            phone:this.state.phone,
            company_id:this.state.is_new_company ? this.state.companyName : this.state.company.value,        
            friendly_url:this.props.match.params["campaign_name"],    
        }
        let userData =  JSON.parse(StorageService.getData("user_details"));

        ApiService.postData("completeProfile", postParams, true).then(
            (response) => {

                if (response.status == "success") {
                    let newData = {
                        ...userData,
                        ...response.data,
                    };
                    StorageService.storeData("user_details", JSON.stringify(newData));   
                    let event_name  = this.props.match.params["event_name"];
                    let campaign_name  =   this.props.match.params["campaign_name"];
                    this.props.history.push("/" + event_name + "/" + campaign_name + "/speakers");                
                }
            }).catch(e=>{

            })
    }
}

export default withRouter(Profile);
