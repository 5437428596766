import React from "react";
import "./style.css";

class PostLoader extends React.Component {
  render() {
    return (
      <div className="customLoaderOuter">
        <img
          src={require("./../../assets/images/velo-logo.png")}
          alt="Logo loader"
          className="customLoaderImg"
        />      
          <p className="customLoaderTxt">{this.props.message}</p>     
      </div>
    );
  }
}
export default PostLoader;
