import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import LeftDrawer from "../../components/leftDrawer";
import DownloadPostButton from "../../components/DownloadPostButton";
import Gallery from "react-photo-gallery";
import SelectedImage from "./SelectedImage";
import SearchByBidNo from "./SearchByBidNo";
import ImageGallery from "../../components/MasonryListing";
// import { photos } from "./photos";

import {
  AiOutlineSearch,
  AiOutlineFilter,
  AiOutlineHome,
} from "react-icons/ai";
import StorageService from "../../services/StorageService";
import ApiService from "../../services/ApiService";
import loader from "../../assets/images/loader.gif";
import Button from "@material-ui/core/Button";
import { IMAGE_CONSOLE_EVENT_ID} from '../../config/constants'
import urlConfig from "../../config/url-config";

const ImageList = [
  {1:'https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg'},
  {2:'https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg'}
];
class DownloadPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAll: false,
      bidNo: StorageService.getData("bidNo")
        ? StorageService.getData("bidNo")
        : "",
      selectedImages: StorageService.getData("selectedImages")
        ? JSON.parse(StorageService.getData("selectedImages"))
        : [],
      totalPrice: 0,
      totalPhotos: 0,
      userDetails:JSON.parse(StorageService.getData("user_details")),
      frImages:[], 
      photos: StorageService.getData("lowResImages")
        ? JSON.parse(StorageService.getData("lowResImages"))
        : [],
      page: 1,
      pageCount: 10,
      isLoading: false,
      fullLoader: false,
      searchImage:true,
      event_details: StorageService.getData("selected_event")
        ? JSON.parse(StorageService.getData("selected_event"))
        : [],
    };

    this.chooseImages = this.chooseImages.bind();
    this.child = React.createRef();
  }

  async componentDidMount() {
    if(this.state.userDetails?.profile_image)  this.findImageUsingFaceRecognition();

    if (this.state.selectedImages.length > 0) this.calculateTotal();

    let event_url = "";
    let search = window.location.search;
    let params = new URLSearchParams(search);
    event_url = params.get("event");

    if (
      event_url &&
      event_url != null &&
      event_url != "" &&
      typeof event_url != undefined
    ) {
      this.setState({ fullLoader: true });
      await this.getEventDetailsByFriendlyURL(event_url);
    }
  }

  toggleSelectAll = () => {
    this.setState({ selectAll: !this.state.selectAll });
  };

  setBidNo = (value) => {
    StorageService.storeData("bidNo", value);
    this.setState({ bidNo: value, photos: [], totalPhotos: 0 }, () => {
      this.setState({ fullLoader: true });
      this.findPhotosByBidNo(1);
    });
  };

  findImageUsingFaceRecognition = () =>{
    this.setState({ fullLoader: true,searchImage:true, frImages:[] },()=>{


      let user =  JSON.parse(StorageService.getData("user_details"));
      let profileImage = user.profile_image.replace(urlConfig.s3Config('bucket_url'),'');
      let fr_image_id = StorageService.getData("fr_event_id")!='null' ? StorageService.getData("fr_event_id") : IMAGE_CONSOLE_EVENT_ID;
      let param = `customer_images=${profileImage}&event_id=${fr_image_id}&thumbnail=false`;
    
      ApiService.getFaceRecognitionImages(param).then(response=>{
        if(response?.matches){
          let matches = response?.matches; 
          this.setState({frImages:Object.values(matches)})
        } 
        this.setState({fullLoader:false});
      }).catch(err=>{
        this.setState({fullLoader:false});
        console.log(err)
      });


    });

    

  }

  imageRenderer = ({ index, left, top, key, photo }) => {
    let selectedImages = this.state.selectedImages;
    const photo_index = selectedImages.findIndex(
      (item) => item.id === photo.id
    );
    return (
      <SelectedImage
        selected={photo_index > -1 ? true : false}
        key={key}
        margin={"2px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
        chooseImages={this.chooseImages}
      />
    );
  };

  chooseImages = (obj) => {
    let selectedImages = this.state.selectedImages;
    const index = selectedImages.findIndex((item) => item.id === obj.id);
    if (index > -1) selectedImages.splice(index, 1);
    else selectedImages.push(obj);

    this.setState({ selectedImages: selectedImages });
    this.calculateTotal();
  };

  goToOrderSummary = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let app_type = params.get("app_type");
    let event = params.get("event");

    StorageService.storeData(
      "selectedImages",
      JSON.stringify(this.state.selectedImages)
    );

    if (event != null && event != "")
      this.props.history.push(
        "/download-posts-summary?app_type=" + app_type + "&event=" + event
      );
    else
      this.props.history.push("/download-posts-summary?app_type=" + app_type);
  };

  calculateTotal() {
    let total = 0;
    let event_details = JSON.parse(StorageService.getData("selected_event"));

    if (event_details.photo_price_type_id == 1)
      // Free
      total = 0;
    else if (event_details.photo_price_type_id == 2)
      // Flat rate
      total = event_details.photo_price;
    else {
      // Cost per photo
      this.state.selectedImages &&
        this.state.selectedImages.length > 0 &&
        this.state.selectedImages.map((item, index) => {
          total = total + item.price;
        });
    }

    this.setState({ totalPrice: total });
  }

  findPhotosByBidNo(page) {
    if (page > 1) this.setState({ isLoading: true });
    let params = {
      bidNo: this.state.bidNo,
      event_id: StorageService.getData("event_id"),
      page: page,
      pageCount: this.state.pageCount,
    };
    ApiService.getData("getLowResPhotosByBidNo", params, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({
            photos: [...this.state.photos, ...response.data.data],
            page: page,
          });
          this.setState({ totalPhotos: response.data.total }, () => {
            setTimeout(() => {
              StorageService.storeData(
                "lowResImages",
                JSON.stringify(this.state.photos)
              );
              this.setState({ isLoading: false, fullLoader: false });
            }, 3000);
          });
          if (response.data.data.length < 1)
            alert("No photos found for the bid number " + this.state.bidNo);
        } else {
        }
      }
    );
  }

  resetFilter() {
    this.setState(
      {
        bidNo: "",
        bidNo: [],
      },
      () => {
        StorageService.removeData("bidNo");
        // StorageService.removeData("event_id");
        // StorageService.removeData("selected_event");
        StorageService.removeData("lowResImages");
      }
    );
  }

  saveImagesToProfile(){
    let params = {
      images: JSON.stringify(this.state.selectedImages),
    };
    ApiService.postData("uploadImages", params, true).then(
      (response) => {
        console.log(response)
        if (response.status == "success") {
          this.props.history.push("/my-downloads?app_type=photo-app");
        } else {
          alert('Something went wrong. Please try again later');
        }
      }
    );
  }


  async getEventDetailsByFriendlyURL(event_url) {
    let params = {
      event_url: event_url,
    };
    ApiService.getData("getByEventFriendlyName", params, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({ event_details: response.data[0] });
          StorageService.storeData(
            "selected_event",
            JSON.stringify(response.data[0])
          );
          StorageService.storeData("event_id", response.data[0].id);
          this.setState({ fullLoader: false });
        } else {
        }
      }
    );
  }

  render() {
    
    if(this.state.frImages.length > 0 && this.state.searchImage) {
      return(       
        <div className="pageOuter">
          <div className="privacyGreenBlock">
            <div className="headerOuter">
              <LeftDrawer></LeftDrawer>
              <h4 className="headerTitle"></h4>
            </div>
            <div className="ppOuter">
              <h4 className="ppTitle">Find your photo</h4>
              <h3 className="ppTitle"></h3>
            </div>

          <div className="inlineSearchOuter">

            <button className="inlineSearchBtn" style={{marginTop:10}}>
                    <AiOutlineSearch
                      className="searchIcon"
                      onClick={() => this.setState({searchImage:false})}
                    />
                  </button>               
                </div>
            </div>
            <ImageGallery
                onSelect={(image, isSelected)=>{
                  if(isSelected) {
                    this.setState({selectedImages:[...this.state.selectedImages,image]});
                  } else {
                    let selectedImages = this.state.selectedImages.filter(item => item!=image);
                    this.setState({selectedImages});
                  }              
                }}
            // images={ImageList}
            images={this.state.frImages[0]}
          /> 
    {this.state.selectedImages.length > 0 &&
      <DownloadPostButton
              count={this.state.selectedImages.length}
              type='btn_save'
              onClickDownloadButton={()=>this.saveImagesToProfile()}
              endText={"Save Images"}
            />
          }
        </div>
      ) 
    }

    return (
      <div className="pageOuter">
        <div className="privacyGreenBlock">
          <div className="headerOuter">
            <LeftDrawer></LeftDrawer>
            <h4 className="headerTitle"></h4>
          </div>
          <div className="ppOuter">
            <h4 className="ppTitle">Find your photo</h4>
            <h3 className="ppTitle"></h3>
          </div>
          {!this.state.fullLoader &&
            this.state.bidNo != "" &&
            this.state.bidNo.length > 0 && (
              <div className="inlineSearchOuter">
                <input
                  type="text"
                  name="inlineSearchInput"
                  className="inlineSearchInput"
                  placeholder="Enter your Bid No. here"
                  value={this.state.bidNo}
                  onChange={(e) => this.setState({ bidNo: e.target.value })}
                />
                <button className="inlineSearchBtn">
                  <AiOutlineSearch
                    className="searchIcon"
                    onClick={() => this.setBidNo(this.state.bidNo)}
                  />
                </button>
                <button className="inlineSearchBtn ">
                  <AiOutlineHome
                    className="searchIcon"
                    onClick={() => this.resetFilter()}
                  />
                </button>
              </div>
            )}
        </div>
        <div className="contentExtremeOuter">
          <div className="pp-content-outer">
              
         
            {!this.state.fullLoader ? (
              this.state.bidNo != "" && this.state.photos.length > 0 ? (
                <>
                  <Gallery
                    photos={this.state.photos}
                    renderImage={this.imageRenderer}
                  />

                  <div className="loadBtnOuter">
                    {this.state.isLoading ? (
                      <Button className="loadBtn">Please wait..</Button>
                    ) : this.state.totalPhotos == this.state.photos.length ? (
                      <p className="pleaseWaitTxt">You have reached the end.</p>
                    ) : (
                      <Button
                        className="loadBtn"
                        onClick={() =>
                          this.findPhotosByBidNo(this.state.page + 1)
                        }
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <SearchByBidNo
                  setBidNo={this.setBidNo}
                  isLoading={this.state.isLoading}
                  findImageUsingFaceRecognition={this.findImageUsingFaceRecognition}
                />
              )
            ) : (
              <div className="veloLoaderOuter">
                <img
                  src={require("./../../assets/images/velo-logo.png")}
                  alt="Logo loader"
                  className="photosLoaderImg"
                />

                <p className="veloLoaderText">
                  Please wait while we load everything
                </p>
              </div>
            )}
          </div>
          {this.state.bidNo != "" && this.state.selectedImages.length > 0 && (
            <DownloadPostButton
              count={this.state.selectedImages.length}
              totalPrice={this.state.totalPrice}
              onClickDownloadButton={this.goToOrderSummary}
              endText={"Next"}
            />
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(DownloadPosts);
