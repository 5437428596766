import React from "react";
import "./style.css";
import { withRouter } from "react-router";

class Landing extends React.Component {
  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let event_name = params.get("event_name");
    let campaign_name = params.get("campaign_name");
    let app_type = params.get("app_type");

    this.id = setTimeout(
      () =>
        this.props.history.push(
          app_type == "photo-app"
            ? "/login?&app_type=" + app_type
            : "/login?event_name=" +
                event_name +
                "&campaign_name=" +
                campaign_name +
                "&app_type=" +
                app_type
        ),
      2000
    ); // redirect to login after 2 seconds
  }

  render() {
    return (
      <div id="landing_container">
        <div id="logo_section">
          <img
            class="fullWidthImage"
            alt="Logo"
            src={require("../../assets/images/landing/logo.png")}
          />
        </div>
        <div id="loader_section">
          <img
            class="fullWidthImage"
            id="loading"
            src={require("../../assets/images/landing/loader.svg")}
            alt="Loading"
          />
        </div>
      </div>
    );
  }
}
export default withRouter(Landing);
