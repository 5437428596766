import React from "react";
import './styles.css'
import { FiSearch,FiX } from "react-icons/fi";
import LeftDrawer from "../leftDrawer";
import awsS3 from "../../../services/aws/s3";
import { withRouter } from "react-router";


class Header extends React.Component {

    state={
        search:false,
        isFocused:false,
        searchvalue:'',
        suggession:[],
        allSuggession:[],
        event_name:this.props.match.params["event_name"],
        campaign_name:this.props.match.params["campaign_name"]
    }
    componentDidMount(){
        awsS3.listBukets((data)=>{           
            let folderNames = data.map(item=>(item.Prefix));
            this.setState({allSuggession:folderNames,suggession:folderNames})
        });
    }
    render(){
        return(
            <header className={this.state.search  ? "serch-header" :  ''}>
                {!this.state.search &&
                 <div>
                 <LeftDrawer/>                
                 <h6>Prawaas 2022</h6>
                 <FiSearch  size="20" onClick={()=>this.setState({search:true})}/>
             </div>
    ||
    <div>
         <LeftDrawer/>  
         <div className="search-wraper"> 
            <input className="search-bar" 
            onChange={(evt)=>{this._handleSearch(evt)}}
            onFocus={()=>this.setState({isFocused:true})}  placeholder="Search here"/>
            {this.state.isFocused
            &&
            <div className="search-suggession">
                {
                this.state.suggession.length > 0 &&
                
                this.state.suggession.map((item,index)=>
                {
                    let url = "/" + this.state.event_name + "/" + this.state.campaign_name + `/search?dir=${item}`;
                return(<span key={index} onClick={()=>this.props.history.push(encodeURI(url))}>{`#${item}`}</span>)
                    })
                ||
                            <span key={1}>No result found</span>  
                
                } 
            </div>
            }
            
         </div>  
         <FiX size="20" style={{paddingLeft:5}} onClick={()=>this.setState({search:false})}/>
    </div>
                }
               
            </header>
        )
    }

        _handleSearch(evt){
            let value = evt.target.value;
            this.setState({searchvalue:value});
  
            if(value.length > 0){
                let filterValues = this.state.allSuggession.filter(item=>{                   
                    return item.toLowerCase().search(value.toLowerCase()) > -1});               
                this.setState({suggession:filterValues});
            }else{
                this.setState({suggession:this.state.allSuggession});

            }
        }
    }


export default withRouter(Header);
