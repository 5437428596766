import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";


class InputSpinner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            counter : props.default ? props.default : props.min,
            max : props.max,
            min : props.min,
            step : props.step,            
        };

    }

    updateCounterVal(type){
        if(type == 'add'){
            if(this.state.counter < this.state.max)
                this.setState({counter : parseInt(this.state.counter) + parseInt(this.state.step)}, () => this.props.onChangeSpinnerValue(this.state.counter));
        }else{
            if(this.state.counter > this.state.min)
                this.setState({counter : parseInt(this.state.counter) - parseInt(this.state.step)}, () => this.props.onChangeSpinnerValue(this.state.counter));
        }        
        
    }
    
    render() {
        return (
            <div className="counterOuter">
                <button className={(this.state.counter > this.state.min) ? "minusBtn" : "minusBtn inactive" }  onClick={() => this.updateCounterVal('minus')}>
                    <AiOutlineMinus className="counterIcon"/>
                </button>
                <input type="text" value={this.state.counter} className="counterVal" readOnly/>
                <button className={(this.state.counter < this.state.max) ? "plusBtn" : "plusBtn inactive" } onClick={() => this.updateCounterVal('add')}>
                    <AiOutlinePlus className="counterIcon"/>
                </button>
            </div>
        );
    }
}
export default withRouter(InputSpinner);
