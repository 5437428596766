import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import LeftDrawer from "../../components/leftDrawer";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="pageOuter">
      <div className="privacyGreenBlock">
        <div className="headerOuter">
          <LeftDrawer></LeftDrawer>
          <h4 className="headerTitle"></h4>
        </div>
        <div className="ppOuter">
          <h4 className="ppTitle">Privacy Policy</h4>
          <h3 className="ppTitle"></h3>
        </div>
      </div>
      <div className="pp-content-outer">
        <p className="pp-content">
          Your privacy is important to us. It is Veloscope's policy to respect
          your privacy regarding any information we may collect from you
          across our website, https://eventforce.ai, and other sites
          we own and operate.
        </p>

        <p className="pp-content">
          We only ask for personal information when we truly need it to
          provide a service to you. We collect it by fair and lawful means,
          with your knowledge and consent. We also let you know why we’re
          collecting it and how it will be used.
        </p>

        <p className="pp-content">
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorized access, disclosure, copying, use or
          modification.
        </p>

        <p className="pp-content">
          We don’t share any personally identifying information publicly or
          with third-parties, except when required to by law.
        </p>

        <p className="pp-content">
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and
          practices of these sites, and cannot accept responsibility or
          liability for their respective privacy policies.
        </p>

        <p className="pp-content">
          You are free to refuse our request for your personal information,
          with the understanding that we may be unable to provide you with
          some of your desired services.
        </p>

        <p className="pp-content">
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have
          any questions about how we handle user data and personal
          information, feel free to contact us.
        </p>

        <p className="pp-content">
          This policy is effective as from 01 November 2021.
        </p>
      </div>
    </div>
    );
  }
}
export default withRouter(PrivacyPolicy);
