import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import "./style.css";
import StorageService from "../../services/StorageService";
import {BiMenu} from 'react-icons/bi';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function LeftDrawer(props) {
  const { event_name, campaign_name } = useParams();

  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const [campaign_details, setCampainDetails] = React.useState(
    JSON.parse(StorageService.getData("campaign_details"))
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onCLickLogout = () => (event) => {
    //console.log("onCLickLogout");
    StorageService.removeData("access_token");
    StorageService.removeData("campaign_details");
    StorageService.removeData("user_details");
    StorageService.removeData("event_id");
    StorageService.removeData("selected_event");
    StorageService.removeData("selectedImages");
    StorageService.removeData("lowResImages");
    StorageService.removeData("bidNo");
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const app_type = params.get("app_type");
  const event = params.get("event");

  // const event_name = params.get("event_name");
  // const campaign_name = params.get("campaign_name");

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
      onClick={toggleDrawer(anchor, false)}
    >
      <List>
        {(!app_type || app_type != "photo-app") && (
          <ListItem button key="home">
            <Link className="meni-item-link" to="home">
              <ListItemText primary="Home" />
            </Link>
          </ListItem>
        )}
        <ListItem button key="profile">
          <Link
            className="meni-item-link"
            to={
              app_type == "photo-app"
                ? "/my-profile?app_type=" + app_type
                : "my-profile"
            }
          >
            <ListItemText primary="profile" />
          </Link>
        </ListItem>
        {app_type && app_type == "photo-app" && (
          <ListItem button key="find-your-photo">
            <Link
              className="meni-item-link"
              to={
                event == null || event == ""
                  ? "find-your-photo?app_type=" + app_type
                  : "find-your-photo?app_type=" + app_type + "&event=" + event
              }
            >
              <ListItemText primary="Find your photo" />
            </Link>
          </ListItem>
        )}
        {app_type && app_type == "photo-app" && (
          <ListItem button key="my-downloads">
            <Link
              className="meni-item-link"
              to={
                event == null || event == ""
                  ? "my-downloads?app_type=" + app_type
                  : "my-downloads?app_type=" + app_type + "&event=" + event
              }
            >
              <ListItemText primary="My Downloads" />
            </Link>
          </ListItem>
        )}
        {(!app_type || app_type != "photo-app") &&
          (campaign_details &&
          campaign_details[0].type &&
          campaign_details[0].type == 1 ? (
            <ListItem button key="all-posts">
              <Link className="meni-item-link" to="campaign-wall">
                <ListItemText primary="Campaign Wall" />
              </Link>
            </ListItem>
          ) : (
            <ListItem button key="all-participants">
              <Link className="meni-item-link" to="all-participants">
                <ListItemText primary="All Participants" />
              </Link>
            </ListItem>
          ))}

        {/* <ListItem button key="privacy-policy">
          <Link
            className="meni-item-link"
            to={"privacy-policy?app_type=" + app_type}
          >
            <ListItemText primary="Privacy Policy" />
          </Link>
        </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem button key="logout">
          <Link
            className="meni-item-link"
            to={
              app_type && app_type == "photo-app"
                ? event && event != null
                  ? "/login?app_type=" + app_type + "&event=" + event
                  : "/login?app_type=" + app_type
                : "/login?event_name=" +
                  event_name +
                  "&campaign_name=" +
                  campaign_name
            }
          >
            <ListItemText onClick={onCLickLogout()} primary="Logout" />
          </Link>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button  variant="outlined" color="success" onClick={toggleDrawer(anchor, true)}>
            <BiMenu className="menu-btn"/>
            {/* <img
              alt="Menu"
              className="menuIcon"
              src={require("../../assets/images/event/menu.svg")}
            /> */}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
            <div className="support-contact">
            <p>For any technical clarifications please reach out to us at</p>
            <a href="mailto:support@veloscope.in">support@veloscope.in</a>
          </div>
          </Drawer>
          
        </React.Fragment>
      ))}
    </div>
  );
}
