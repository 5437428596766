import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";
import ApiService from "../../services/ApiService";
import Typography from '@material-ui/core/Typography';

class AllParticipants extends React.Component {

    constructor(props) {
        super(props);  
        this.state = {
            participantArr: [],
            campaign_details: JSON.parse(StorageService.getData('campaign_details')),
            is_loading: false,
            page : 1,
            prevY: 0
        };      
    }

    async componentDidMount() {
        await this.getAllParticipants(this.state.page);

        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        };
        
        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
        );
        this.observer.observe(this.loadingRef);
    }    

    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        if (this.state.prevY > y) {           
          let newPageNo   = this.state.page + 1 ;
          this.getAllParticipants(newPageNo);
          this.setState({ page: newPageNo });
        }
        this.setState({ prevY: y });
    }
    
    async getAllParticipants(page){
        this.setState({is_loading : true});

        let getParams = {
            campaign_id: this.state.campaign_details[0].id,
            status:2,
            pageCount:5,
            page:page,
        };

        ApiService.getData("getAllParticipants", getParams, true).then((response) => {    
            if (response.status == "success") {  
                this.setState({is_loading : false});
                // this.setState({participantArr : response.data.data});     
                this.setState({ participantArr: [...this.state.participantArr, ...response.data.data] });
            } else {      
                this.setState({is_loading : false});              
            }
        });
    }

    convertToEmbeded(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
    
        return (match && match[2].length === 11)
          ? match[2]
          : null;
    }

    goBack() {
        this.props.history.push(
          "/" +
          this.props.match.params["event_name"] +
          "/" +
          this.props.match.params["campaign_name"] +
          "/home"
        );
    }

    render() {

        const loadingCSS = {
            height: "100px",
            margin: "30px"
          };
      
        // To change the loading icon behavior
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
          
        return (
            <div className="main-container">
                <div id="header">
                    <nav id="back" onClick={() => this.goBack()}></nav>
                    <h1 id="page-title">All Participants</h1>
                </div>    

                <div className="participant_list_outer">

                    {
                        (this.state.participantArr.length > 0) && this.state.participantArr.map((item, key) => (
                            <div className="participantSingleCard" key={key}>
                                <iframe 
                                    className="participantVideoIframe"
                                    src={'//www.youtube.com/embed/'+this.convertToEmbeded(item.video_url)}
                                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen
                                ></iframe>
                                <div className="participantInfo">
                                    <Typography className="participantName" gutterBottom variant="subtitle1" component="div">
                                        {item.name}
                                    </Typography>
                                    <Typography className="participantAge" variant="subtitle1" component="div">
                                        {item.age}
                                    </Typography>
                                </div>                        
                                <Typography className="participantSchool" gutterBottom variant="subtitle1" component="div">
                                    {item.school_name}
                                </Typography>
                            </div>
                        ))
                    }                   
                   
                    <div
                        ref={loadingRef => (this.loadingRef = loadingRef)}
                        style={loadingCSS}
                    >
                        <span style={loadingTextCSS}>Loading...</span>
                    </div>

                </div>

            </div>            
        );
    }
}
export default withRouter(AllParticipants);
