import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import StorageService from "../../services/StorageService";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //Temporary solution to solve expired token
    this.logoutUser();
  }

  logoutUser() {
    StorageService.removeData("access_token");
    StorageService.removeData("campaign_details");
    StorageService.removeData("user_details");
    StorageService.removeData("ticket_count");
  }

  goToHomePage() {
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/home"
    );
  }

  render() {
    return (
      <div className="errorPageOuter">
        <img
          src={require("../../assets/images/something-went-wrong.svg")}
          alt="errorImg"
          className="errorImg"
        />
        <p className="errorMsgTitle">Whoops !</p>
        <p className="errorMsg">Something's went wrong....</p>
        <Button className="homeBtn" onClick={() => this.goToHomePage()}>
          Take me home
        </Button>
      </div>
    );
  }
}
export default withRouter(ErrorPage);
