import React,{useState, useEffect, useRef} from "react";
import { useFilePicker } from 'use-file-picker';
import StorageService from "../../services/StorageService";
import {RiUserSearchLine} from 'react-icons/ri';
import CameraModule from "../../components/Camera";


const ProfileImage = ({uploadImage,isLoading,btnName, infoContent, skipBtn,onCamChange,onSkipSearch, guestImage,faceSearchImage,isFrSearching })=>{
  
      let user  = JSON.parse(StorageService.getData("user_details"));
      const [imagePath,setImagePath] =  useState(user?.profile_image ? user?.profile_image : "/user-avatar.png");
      const [image,setImage] = useState('');
      const [enableImgSearch,setEnableImgSearch] = useState((user?.profile_image || guestImage) ? true : false);
     const [enableCamera,setEnableCam] = useState(false);
    const imageRef = useRef(null);

    useEffect(()=>{        
        if(guestImage){
            setImagePath(guestImage);
        }
    },[])
    useEffect(()=>{onCamChange(enableCamera)},[enableCamera])
    if(enableCamera) {
      return <CameraModule upload={(type,image)=>{
        setImagePath(image);
        setEnableCam(false);
         setImage(image);       
         setEnableImgSearch(true);
         if(type =='camera') {
            setImagePath(URL.createObjectURL(image));
            var reader = new FileReader();
                reader.readAsDataURL(image); 
                reader.onloadend = function() {
                var base64data = reader.result;                
                setImage(base64data); 
                }
         }
        }}/>
    }
    return(
        <>
        <div className={`imageWraper image-upload-wrapper`}>
        <div className="profile-image">
            <img ref={imageRef} src={imagePath}/>          
        </div>
        <div className="upload-info-wrapper">
            <div className="imageupload-btn">
                <button  className={isFrSearching ? 'selectBtn disabled' : 'selectBtn'}  onClick={() =>!isFrSearching ? setEnableCam(true) : null}>Upload  your selfie/image</button>
            </div>
            {
                !infoContent &&
                <ol>
                <li><p>Upload an image of yourself where your face is clearly visible. </p></li>
                <li><p>Your image will be used for facial recognition technology.</p></li>
                <li><p>All matched images will be listed in the profile tab.</p></li>
            </ol>
            }
           
        </div>
    </div>
            {
                 enableImgSearch &&
                <button
                onClick={()=>{image ? uploadImage(image) : faceSearchImage();}}
                className="searchBtn"
            
            >{isLoading ? 'Uploading...' : btnName}</button> ||
            <button
            onClick={()=>{}}
            style={{display:'flex',justifyContent:'center',alignItems:'center'}}
            className="searchBtn disabled-btn"

        ><div style={{display:'flex',position:'relative'}}><div><RiUserSearchLine color="#fff" style={{paddingRight:10}} size={20}  /> {btnName}</div>
        {infoContent && <p style={{fontSize:8, position:'absolute', top:15,right:0}}>(For unidentified images)</p>}</div>
        </button>
            }
{skipBtn &&
    <button
            onClick={()=>{onSkipSearch()}}
            style={{display:'flex',justifyContent:'center',alignItems:'center'}}
            className="searchBtn"

        >Skip and Proceed</button>
}

               
</>
    )
  }

  ProfileImage.defaultProps ={
    btnName:'Save Image',
    infoContent:null,
    skipBtn:false,
    onCamChange:()=>{},
    guestImage:null,
    isFrSearching:false,
  }
export default ProfileImage;
