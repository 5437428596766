import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";

class InvalidPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="expiredPageOuter">
        <img
          src={require("../../assets/images/invalid-campaign.svg")}
          alt="expiredImg"
          className="expiredImg"
        />
        <p className="expiredMsgTitle">Whoops !</p>
        <p className="expiredMsg">Campaign not found.</p>
      </div>
    );
  }
}
export default withRouter(InvalidPage);
