import React,{useEffect, useState} from "react";
import "./style.css";
import Button from "@material-ui/core/Button";
import { AiOutlineSearch, AiOutlineFilter } from "react-icons/ai";
import StorageService from "../../services/StorageService";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ApiService from "../../services/ApiService";
import { BsArrowLeft } from "react-icons/bs";
import { useFilePicker } from 'use-file-picker';
import S3 from "../../services/aws/s3";


const ProfileImage = ({onSelect})=>{
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
      accept: 'image/*',
      multiple:false,
      readAs:'DataURL',
    });
    let user  = JSON.parse(StorageService.getData("user_details"));

    const [imagePath,setImagePath] =  useState(user?.profile_image ? user?.profile_image : "/user-avatar.png");

    useEffect(()=>{
      if(filesContent.length > 0){
          onSelect(filesContent[0]);
          setImagePath(filesContent[0].content);
      }
    },[filesContent])
  return(
      <div className="imageWraper">
      <div className="profile-image">
          <img src={imagePath}/>          
      </div>
      <button  className="selectBtn"  onClick={() => openFileSelector()}>Upload  your selfie/image</button>
  </div>
  )
}

class SearchByBidNo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bidNoVal: StorageService.getData("bidNo")
        ? StorageService.getData("bidNo")
        : "",
      showEventSearch: false, //Currently Event Are hardcoded
      userProfile: JSON.parse(StorageService.getData("user_details")),
      showBidSearch: true,
      event_id: StorageService.getData("event_id")
        ? StorageService.getData("event_id")
        : "",
      events: [],
      selected_event: StorageService.getData("selected_event")
        ? JSON.parse(StorageService.getData("selected_event"))
        : "",
      showBackArrow: false,
      image:null,
    };
  }

  componentDidMount() {
    if (
      StorageService.getData("event_id") &&
      StorageService.getData("event_id") != ""
    ) {
      this.setState({ showEventSearch: false }, () => {
        setTimeout(() => {
          this.setState({ showBidSearch: true }, () => {
            this.setState({ showBackArrow: true });
          });
        }, 100);
      });
    }
  }

  handleButtonClick(type) {

    if (type == "event") {
      this.setState({ showEventSearch: false }, () => {
        setTimeout(() => {
          this.setState({ showBidSearch: true }, () => {
            // setTimeout(() => {
            this.setState({ showBackArrow: true });
            // }, 200);
          });
          StorageService.storeData(
            "selected_event",
            JSON.stringify(this.state.selected_event)
          );
          StorageService.storeData("event_id", this.state.event_id);
        }, 100);
      });
    } else if (type == "bid") {
          //If New Image Selected
        if(this.state.bidNoVal == "" && this.state.image!=null) {
          this.uploadImage();
          return;
          }
          if(this.state.userProfile?.profile_image && this.state.bidNoVal == ""){
            this.props.findImageUsingFaceRecognition();
            return;
          }
    
      this.props.setBidNo(this.state.bidNoVal);
    }
  }

  searchEvents(value) {
    if (value && value != "") {
      ApiService.getData("searchEvent", { keyword: value }, true).then(
        (response) => {
          if (response.status == "success") {
            this.setState({
              events: response.data,
            });
          } else {
          }
        }
      );
    }
  }

  selectEvent(event) {
    this.setState({ selected_event: event });
    this.setState({ event_id: event.id });
  }

  handleBackButtonClick() {
    this.setState({ showBidSearch: false }, () => {
      setTimeout(() => {
        this.setState({ showEventSearch: true }, () => {
          this.setState({ showBackArrow: false });
        });
      }, 100);
    });
  }

  resetAll() {
    this.setState({ selected_event: "", bidNoVal: "", event_id: "" });
  }


  uploadImage(){
    let image = this.state.image;      
    let timestamp = + new Date();     
    S3.upload(image.content,timestamp+image.name,(data)=>{
        this.saveProfileLink(data.Location);
    });
}

saveProfileLink(link){
    let user =  JSON.parse(StorageService.getData("user_details"));
    let postParams = {
        profile_image:link,
    };
    ApiService.postData("updateProfileImage", postParams, true).then(
        (response) => {
            if (response.status == "success") {
                let newData = {
                    ...user,
                    profile_image:link,
                };
                StorageService.storeData("user_details", JSON.stringify(newData));
                this.props.findImageUsingFaceRecognition();
                // this.props.history.push("/" + this.state.event_name + "/" + this.state.campaign_name + "/speakers");
            }
        }).catch(e=>{

        })
}

  render() {
    return (
      <div className="searchFormOuter">
        {this.state.showBackArrow && (
          <BsArrowLeft
            className="showEventSearch"
            onClick={() => this.handleBackButtonClick()}
          />
        )}

{ this.state.showEventSearch  && 
        <div className="inputOuter">
          <Slide
            direction="right"
            in={this.state.showEventSearch}
            mountOnEnter
            unmountOnExit
          >
            <div className="searchInputOuter">
              <Autocomplete
                freeSolo
                options={this.state.events}
                sx={{ width: 300 }}
                value={this.state.selected_event.title}
                renderInput={(params) => (
                  <TextField
                    className="eventSearchField"
                    {...params}
                    label="Search event here"
                    onChange={(e) => this.searchEvents(e.target.value)}
                  />
                )}
                onChange={(event, value) => this.selectEvent(value)}
              />
            </div>
          </Slide>
          <Slide
            direction="left"
            in={this.state.showBidSearch}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 500 }}
          >
            <div className="searchInputOuter">
              <input
                id="search"
                type="search"
                placeholder="Enter your bid no. here"
                autofocus
                required
                className="searchBidInput"
                onChange={(e) => this.setState({ bidNoVal: e.target.value })}
                value={this.state.bidNoVal}
              />
            </div>
          </Slide>
        </div>
  }
{ !this.state.showEventSearch  && 
        <div>
          {/* <span className="labelText">OR</span> */}
          <ProfileImage onSelect={(image)=>this.setState({image:image})}/>

        </div>
  }
        

        {this.props.isLoading ? (
          <button className="searchBtn">Please wait...</button>
        ) : this.state.showEventSearch ? (
          <button
            className="searchBtn"
            onClick={() => this.handleButtonClick("event")}
          >
            Next
          </button>
        ) : this.state.showBidSearch ? (
          <button
            className="searchBtn"
            onClick={() => this.handleButtonClick("bid")}
          >
            <AiOutlineSearch className="searchIcon buttonLeftIcon" />
            Search
          </button>
        ) : null}

       
      </div>
    );
  }
}
export default SearchByBidNo;
