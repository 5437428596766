import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";

import ApiService from "../../services/ApiService";
import { any } from "prop-types";

class AllCampaignList extends React.Component {
  constructor() {
    super();

    this.state = {
      campaings: [],
      isReady: false,
      totalCamps: 0,
      isBottomReached: false,
      currentPgNo: 0,
      isLoading: false,
      isApiEnd: false,
      campaign_details: JSON.parse(StorageService.getData("campaign_details")),
    };
  }

  componentDidMount() {
    this.getByCampaignFriendlyName();
    // document.addEventListener("scroll", this.trackScrolling);
  }

  getByCampaignFriendlyName() {
    this.setState({ isLoading: true });
    let getParams = {
      campaign_url: this.props.match.params["campaign_name"],
    };

    ApiService.getData("getByCampaignFriendlyName", getParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({ campaign_details: response.data });
          this.setState({ isLoading: false });

          StorageService.storeData(
            "campaign_details",
            JSON.stringify(response.data)
          );

          this.getAllCampaigns(this.state.currentPgNo + 1);
        } else {
          if (response.message == "Token has expired") {
            StorageService.removeData("access_token");
            StorageService.removeData("campaign_details");
            StorageService.removeData("user_details");
            this.props.history.push(
              "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/login"
            );
          } else {
            this.props.history.push(
              "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/error"
            );
          }
        }
      }
    );
  }

  getAllCampaigns(pgNo) {
    this.setState({ isLoading: true });
    let getParams = {
      page: pgNo,
      campaign_id: this.state.campaign_details[0].id,
    };

    ApiService.getData("getAllPosts", getParams, false).then((response) => {
      if (response.status == "success") {
        this.setState({
          campaings: [...this.state.campaings, ...response.data.data],
          currentPgNo: pgNo,
        });
        this.setState({ totalCamps: response.data.total });
        this.setState({ isReady: true });
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 2000);

        if (response.data.data.length < 1) {
          this.setState({ isApiEnd: true });
        }

        // this.state.isBottomReached = false;
      } else {
        console.log("error");
        if (response.message == "Token has expired") {
          StorageService.removeData("access_token");
          StorageService.removeData("campaign_details");
          StorageService.removeData("user_details");
          this.props.history.push(
            "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/login"
          );
        } else {
          this.props.history.push(
            "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/error"
          );
        }
      }
    });
  }

  // isBottom(el) {
  //   // return el.getBoundingClientRect().bottom <= window.innerHeight;
  // }

  componentWillUnmount() {
    // document.removeEventListener("scroll", this.trackScrolling);
  }

  // trackScrolling = () => {
  //   const wrappedElement = document.getElementById("allCampList");
  //   if (this.isBottom(wrappedElement)) {
  //     this.state.isBottomReached = true;
  //     setTimeout(() => {
  //       this.getAllCampaigns(this.state.currentPgNo + 1);
  //     }, 2000);
  //     // document.removeEventListener("scroll", this.trackScrolling);
  //   }
  // };

  render() {
    return (
      <div id="allCampList" className="campaigns-items">
        {this.state.isReady === true ? (
          <div>
            <h3 className="campaignPostHeading">
              <span className="campaignHeadingTxt">Posts</span>
              <span className="campaignPostCount">{this.state.totalCamps}</span>
            </h3>
            {this.state.campaings.map((camp) => (
              <div class="item">
                <img src={camp.image_path} />
              </div>
            ))}

            <div className="clr"></div>

            {this.state.isLoading === true && this.state.isApiEnd === false ? (
              <div className="campaignLoader">
                <img
                  src={require("./../../assets/images/velo-logo.png")}
                  alt="Logo loader"
                  className="campLoadingImg"
                />
                <span className="campLoadingTxt">Loading...</span>
              </div>
            ) : null}

            {this.state.isLoading !== true && this.state.isApiEnd === false ? (
              <div className="loadBtnOuter">
                <Button
                  className="loadBtn"
                  onClick={() =>
                    this.getAllCampaigns(this.state.currentPgNo + 1)
                  }
                >
                  Load More
                </Button>
              </div>
            ) : null}

            {this.state.isApiEnd === true ? (
              <div className="loadBtnOuter">
                <span className="campLoadingTxt">
                  You have reached the end !
                </span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(AllCampaignList);
