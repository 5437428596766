import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";

class ExpiredPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="expiredPageOuter">
        <img
          src={"/clock.jpg"}
          alt="expiredImg"
          className="expiredImg"
        />
        <p className="expiredMsgTitle">Whoops !</p>
        <p className="expiredMsg">The event is not currently ongoing.</p>
        <ol>
          <li>Verify the event date and time with the event organizer or official website.</li>
          <li>Wait until the event start time and try again.</li>
        </ol>
      </div>
    );
  }
}
export default withRouter(ExpiredPage);
