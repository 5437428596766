import React from "react";
import './style.css'
import Header from '../../../components/Conference/Header'
import { withRouter } from "react-router";
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import ApiService from "../../../services/ApiService";

const UserBox =({user})=>{
    return(
        <div className="user-box">         
        <img src={user.profile_image}/>
            <div className="user-text-wraper">
            <span>{user.name}</span>
            {/* <span>abilashts@gmail.com</span> */}
        </div>
    </div>
    )
}


const ParticipantListing = ({item})=>{
    return(
        <div className="list-group">
                    <div className="company-details">
                        <img className="rounded" src="https://1000logos.net/wp-content/uploads/2020/05/Wipro-logo.jpg"/>
                        <h5>{item.company_name}</h5>
                    </div>

                    <ScrollMenu>
                        {
                            item.users.map(user=>(
                                <UserBox user={user}/>                     
                            ))
                        }
                       </ScrollMenu>
                </div>
    )
}
class Attendee extends React.Component {

        state ={
            users:[],
        }
    componentDidMount(){
        this.getData();
    }

    getData(){
        let getParams ={
            campaign_slug:this.props.match.params["campaign_name"],
            type:'attende',
        };
        // speaker
        ApiService.getData("getParticipant", getParams, true).then((response) => {  
            if(response.status =='success'){
                let {data} = response;
                this.setState({users:data});
                console.log(data);
            }  
        })
    }
render(){
    let event_name  = this.props.match.params["event_name"];
    let campaign_name  =   this.props.match.params["campaign_name"];

    return(
        <div>
            <Header />
                <div className="tab-bar">
                <div  onClick={()=>{ this.props.history.push("/" + event_name + "/" + campaign_name + "/speakers");}}>
                        <p >Speaker</p>
                    </div>
                    <div className="active" onClick={()=>{ this.props.history.push("/" + event_name + "/" + campaign_name + "/attendee");}}>
                        <p>Attendee</p>
                    </div>
                </div>                
                {
                this.state.users.length > 0 && 
                this.state.users.map(item=>(<ParticipantListing item={item}/>)) 
||
                <div className="empty-state"><p>There is no attendees at the moment.</p></div>
                }                
        </div>
    )
}
}


export default withRouter(Attendee);
