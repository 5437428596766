import React from "react";
import "./style.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import ParticipantCard from "../../components/ParticipantCard";
import Divider from '@material-ui/core/Divider';
import StorageService from "../../services/StorageService";
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


// const { width, height } = useWindowSize()

class CreateVideoPostSuccess extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }


    goToHome() {
        this.props.history.push(
            "/" +
            this.props.match.params["event_name"] +
            "/" +
            this.props.match.params["campaign_name"] +
            "/home"
        );
    }

    render() {
        return (
            <div className="successOuter">
                <Confetti
                    width={372}
                    height={900}
                    numberOfPieces={300}
                    recycle={false}
                    tweenDuration={20000}
                />
                <div id="cust_reg_container" className="successContentOuter">
                    <div id="content-area">
                        <h2 className="videoSuccessTxt">
                            Congrats
                        </h2>  
                        <p className="videoSuccessTxtInfo">
                            Good luck!
                        </p>   

                        <p className="videoSubInfoMsg">
                            Thank you for participating in Mahachef 2021.                            
                        </p>   

                        <div className="successImageOuter">
                            <img
                                src={require("../../assets/images/reg-success.jpg")}
                                alt="success"
                                className="video-success-image"
                            />
                        </div>

                    </div>

                    <div id="footer">
                        <div id="goToHomeLink">
                            <a onClick={() => this.goToHome()}>Go back to home</a>
                        </div>
                    </div>

                </div >
            </div>
        );
    }
}
export default withRouter(CreateVideoPostSuccess);
