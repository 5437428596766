import React from "react";
import "./style.css";
import Participant from "../../components/Participant";
import ParticipantList from "../../components/ParticipantList";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import LeftDrawer from "../../components/leftDrawer";
import ApiService from "../../services/ApiService";
import StorageService from "../../services/StorageService";
import AuthenticationService from "../../services/AuthenticationService";
import SchoolIcon from "@material-ui/icons/School";
import RoomIcon from "@material-ui/icons/Room";
import loaderNormal from "../../assets/images/loader-normal.gif";

const _ = require("lodash");
class EventDetail extends React.Component {
  constructor(props) {
    super(props);

    AuthenticationService.isCampaignActive(
      this.props.match.params["campaign_name"],
      this.props.match.params["event_name"]
    );

    this.state = {
      campaign_details: "",
      isLoading: true,
      participantArr: [],
      allParticipantArr: [],
      totalCount: 0,
      isReady: false,
    };

    this.getByCampaignFriendlyName();
  }

  componentDidMount() {}

  submitParticipation() {
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/registration-ticket"
    );
  }

  viewParticipants() {
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/participants"
    );
  }

  getByCampaignFriendlyName() {
    this.setState({ isLoading: true });
    let getParams = {
      campaign_url: this.props.match.params["campaign_name"],
    };

    ApiService.getData("getByCampaignFriendlyName", getParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({ campaign_details: response.data });

          StorageService.storeData(
            "campaign_details",
            JSON.stringify(response.data)
          );

          this.getUserParticipantsList(response.data);
          this.getAllParticipants(response.data);
        } else {
          if (response.message == "Token has expired") {
            StorageService.removeData("access_token");
            StorageService.removeData("campaign_details");
            StorageService.removeData("user_details");
            this.props.history.push(
              "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/login"
            );
          } else {
            this.props.history.push(
              "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/error"
            );
          }
        }
      }
    );
  }

  getUserParticipantsList(campaign_details) {
    let getParams = {
      campaign_id: campaign_details[0].id,
    };
    ApiService.getData("getUserParticipants", getParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({ participantArr: response.data });
          this.setState({ isLoading: false });
        } else {
        }
      }
    );
  }

  getAllParticipants(campaign_details) {
    let getParams = {
      campaign_id: campaign_details[0].id,
      status: 1,
      pageCount: 10,
      page: 1,
    };

    ApiService.getData("getAllParticipants", getParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({ isReady: true });
          this.setState({ allParticipantArr: response.data.data });
          this.setState({ totalCount: response.data.length });
        } else {
          if (response.message == "Token has expired") {
            StorageService.removeData("access_token");
            StorageService.removeData("campaign_details");
            StorageService.removeData("user_details");
            this.props.history.push(
              "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/login"
            );
          } else {
            this.props.history.push(
              "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/error"
            );
          }
        }
      }
    );
  }

  truncate(str) {
    return str.length > 16 ? str.substring(0, 15) + "..." : str;
  }

  goToCreateImagePost() {
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/create-post"
    );
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="loaderOuter">
          <img src={loaderNormal} alt="loading..." className="loaderImage" />
          <p className="loaderText">Please while we fetch the event details</p>
        </div>
      );
    } else {
      return (
        <div className="pageOuter">
          <div className="greenBlock">
            <div className="headerOuter">
              <LeftDrawer></LeftDrawer>

              <div className="eventImgOuter">
                {this.state.campaign_details &&
                this.state.campaign_details[0].logo != null ? (
                  <img
                    src={this.state.campaign_details[0].logo}
                    // src='https://velo.hubcelab.in/static/media/logo.5ba77706.png'
                    alt="Event"
                    className="eventImg"
                  />
                ) : null}
              </div>
            </div>
            {this.state.campaign_details ? (
              <div className="eventDetailsOuter">
                {this.state.campaign_details ? (
                  <p className="headerTitleHome">
                    {this.state.campaign_details[0].title}
                  </p>
                ) : null}
                <p className="eventDetails">
                  {this.state.campaign_details[0].description}
                </p>

                {this.state.campaign_details &&
                this.state.campaign_details[0].type &&
                this.state.campaign_details[0].type == 1 ? (
                  <Button
                    className="participateBtn"
                    onClick={() => this.goToCreateImagePost()}
                  >
                    Enroll
                  </Button>
                ) : this.state.participantArr &&
                  this.state.participantArr.length > 0 ? (
                  <Button
                    className="participateBtn"
                    onClick={() => this.viewParticipants()}
                  >
                    View participants
                  </Button>
                ) : (
                  <Button
                    className="participateBtn"
                    onClick={() => this.submitParticipation()}
                  >
                    Enroll
                  </Button>
                )}

                <div className="clr"></div>
                <p className="eventAdvertText">
                  An experience by eventforce.ai
                </p>
              </div>
            ) : null}
          </div>
          <div className="participantBlock">
            {this.state.campaign_details &&
            this.state.campaign_details[0].type &&
            this.state.campaign_details[0].type == 1 ? (
              <ParticipantList />
            ) : (
              <div>
                {this.state.isReady === true ? (
                  <div>
                    <h3 className="participantHeading">
                      <span className="participantHeadingTxt">
                        Recent Activity
                      </span>
                      <span className="participantCount">
                        {this.state.totalCount}
                      </span>
                    </h3>
                    {this.state.allParticipantArr.map((participant) => (
                      <div className="participantListNew">
                        <div className="userProfileImageDiv">
                          <img
                            src={require("../../assets/images/chef-avatar.jpg")}
                            alt="User"
                            className="userProfileImage"
                          />
                        </div>
                        <div className="width-80">
                          <div className="nameDiv">
                            <p className="infoTextName">
                              {/* {participant.name} */}
                              {this.truncate(participant.name)}
                            </p>
                          </div>
                          <div className="ageDiv">
                            <p className="infoTextRight">
                              ( {participant.age} yrs )
                            </p>
                          </div>
                        </div>
                        <div className="width-80">
                          <div className="schoolDiv">
                            <p className="infoText">
                              <SchoolIcon className="infoIcons" />
                              {/* {participant.school_name} */}
                              {this.truncate(participant.school_name)}
                            </p>
                          </div>
                          <div className="districtDiv">
                            <p className="infoTextRight">
                              {/* <RoomIcon className="infoIcons"/>  */}
                              {participant.district}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
export default withRouter(EventDetail);
