import toast from 'react-hot-toast'

export default {
    welcome:function(){
        toast((t) => (
            <span style={{fontSize:16,fontWeight:'bold'}}>Welcome to Eventforce! <span style={{fontSize:14,fontWeight:'normal'}}>You can find all your photos from here.</span></span>
          ),{duration:3000});
    },
    error:function(message)
    {
        toast.error(message);
    },
    show:function(message)
    {
        toast(message);
    }
}