import React from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import "./imagecropper.css";
import frame from "./../../assets/images/frame.png";
import frameTst from "./../../assets/images/74095395.png";
import groupSample from "./../../assets/images/group.jpg";
import Button from "@material-ui/core/Button";
import ApiService from "../../services/ApiService";
import Fab from "@material-ui/core/Fab";
import GetAppIcon from "@material-ui/icons/GetApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import imageCompression from "browser-image-compression";
import PostLoader from "../postLoader";
import StorageService from "../../services/StorageService";

import { Stage, Layer, Star, Text, Image } from "react-konva";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { animateScroll } from "react-scroll";
import { Constants } from "../../config";

const ThemeContext = React.createContext('red');

class ImageCropper extends React.Component {
  constructor() {
    super();
    this.state = {
      imageDestination: "",
      showPreview: false,
      sourceFile: "",
      frameImg: "",
      isPublished: "",
      isLoading: false,
      isPublishing: false,
      post_link: "",
      post_id: "",
      user: JSON.parse(StorageService.getData("user_details")),
      isPostCreating: false,
      customerImg: "",
      templates: "",
      template_id: "",
      template_x: "",
      template_y: "",
      template_width: "",
      template_height: "",
      transparent_area_width: "",
      transparent_area_height: "",
      campaign_details: JSON.parse(StorageService.getData("campaign_details")),
      loaderMessage: "Loading templates !!!",
      current_template_index: 1,
      info_label: "Choose a template",
      info_label_2: "",
      info_label_0: "STEP 1",
      chkbox: true,
    };
    this.imageElement = React.createRef();
    this.stageRef = React.createRef();

    
  }

  componentWillMount() {
    this.getCampaignTemplates(this.state.campaign_details[0].id);

    this.updateCheckboxVal = this.updateCheckboxVal.bind(this);
  }

  viewPreview(val) {
    this.generateCanvas();
    this.setState({
      isPostCreating: true,
    });
    setTimeout(() => {
      this.setState({ showPreview: val, isPostCreating: false });
    }, 2000);

    this.setState({ info_label_0: "STEP 3" });
    this.setState({ info_label: "Publish your post" });
    this.setState({ info_label_2: "" });
  }

  async onChange(e) {
    this.setState({
      sourceFile: "",
      isLoading: true,
    });
    this.setState({ loaderMessage: "Loading your image.." });
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];


    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      reader.onloadend = () => {

        let userImageFile = new window.Image();
        userImageFile.src = reader.result;

        this.setState({
          sourceFile: reader.result,
          isLoading: false,
          showPreview:true,
          customerImg:userImageFile,
        });
        this.setState({ loaderMessage: "" });

        this.initiateCropper();
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  initiateCropper() {
    const cropper = new Cropper(this.imageElement.current, {
      dragMode: "move",
      // aspectRatio: 16 / 9,
      aspectRatio:
        this.state.transparent_area_width / this.state.transparent_area_height,
      restore: true,
      guides: false,
      center: true,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      crop: () => {
        if (this.state.showPreview !== true) {
          const cropImgCanvas = cropper.getCroppedCanvas({
            imageSmoothingQuality: "high",
          });

          this.setState({
            imageDestination: cropImgCanvas.toDataURL("image/png"),
          });
        }
      },
    });
  }

  handleAfterPostGeneration() {
    this.props.onPostGeneration();
  }

  downloadImage() {
    var download = document.getElementById("download");
    // var image = document
    //   .getElementById("finalOutputCanvas")
    //   .toDataURL("image/jpg");

    // var parent = document.querySelector(".konvajs-content");
    // var children = parent.children;
    // var image = children.toDataURL("image/jpg");

    this.stageRef.current.crossOrigin = "anonymous";
    var image = this.stageRef.current.getStage().toDataURL();

    download.setAttribute("href", image);
    download.click();
  }

  publishImage() {
    if (this.state.chkbox) {
      this.setState({
        isPublishing: true,
      });

      var parent = document.querySelector(".konvajs-content");
      // var children = parent.children;

      // children[0].crossOrigin = "anonymous";
      // var image = children[0].toDataURL("image/jpg");

      // Below code commented by as it reduces output image quality

      this.stageRef.current.crossOrigin = "anonymous";
      var image = this.stageRef.current.getStage().toDataURL({ pixelRatio: 3 });

      this.postImageData(image);

      this.setState({ info_label_0: "STEP - 4" });
      this.setState({
        info_label:
          "Share your image and download your original from profile page",
      });
      this.setState({ info_label_2: "" });
    } else {
      alert(
        "Please agree to the terms & conditions before publishing your post."
      );
    }
  }

  postImageData(image) {
    let postParams = {
      email: this.state.user["email"],
      name: this.state.user["firstName"] + " " + this.state.user["lastName"],
      image: image,
      campaign_id: this.state.campaign_details[0].id,
    };

    ApiService.postData("createImage", postParams, true).then((response) => {
      if (response.status == "success") {
        this.setState({
          isPublished: true,
          isPublishing: false,
          post_link: response.data.image,
          post_id: response.data.image_id,
        });

        this.props.onPublished();
        this.scrollToShare();
      } else {
        if (response.message == "Token has expired") {
          StorageService.removeData("access_token");
          StorageService.removeData("campaign_details");
          StorageService.removeData("user_details");
          this.props.history.push(
            "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/login"
          );
        } else {
          this.props.history.push(
            "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/error"
          );
        }
      }
    });
  }

  sharePostToFB() {
    let postLink =
      Constants.POST_SHARER_URL +
      this.state.post_link +
      "&post-quote=" +
      this.state.campaign_details[0].share_post_quote;
    window.open(
      "https://www.facebook.com/sharer.php?u=" +
        postLink +
        "&quote=" +
        this.state.campaign_details[0].share_post_quote
    );
  }

  sharePostToWA() {
    let postLink =
      Constants.POST_SHARER_URL +
      this.state.post_link +
      "&post-quote=" +
      this.state.campaign_details[0].share_post_quote;
    window.open(
      "whatsapp://send?text=" +
        this.state.campaign_details[0].share_post_quote +
        "::" +
        postLink
    );
    // window.open(
    //   "https://web.whatsapp.com/send?text=MahaMarathon 2021::"+ this.state.post_link
    // );
  }

  sharePostToTW() {
    let postLink =
      Constants.POST_SHARER_URL +
      this.state.post_link +
      "&post-quote=" +
      this.state.campaign_details[0].share_post_quote;
    window.open(
      "https://twitter.com/share?url=" +
        postLink +
        "&text=" +
        this.state.campaign_details[0].share_post_quote
    );
  }

  generateCanvas() {
    // var canvas = document.getElementById("finalOutputCanvas");
    // var context = canvas.getContext("2d");
    // context.clearRect(0, 0, canvas.width, canvas.height);
    // var imageObj1 = new Image();
    // var imageObj2 = new Image();
    // imageObj1.onload = function () {
    //   context.drawImage(imageObj1, 0, 0);
    // };
    // // imageObj1.src = frame;
    // imageObj1.src = frameTst;
    // imageObj2.src = this.state.imageDestination;
    // imageObj2.onload = function () {
    //   context.drawImage(imageObj2, 70, 301, 1070, 618);
    //   // context.drawImage(imageObj2, 32, 128, 438, 248);
    // };

    let groupSampleFile = new window.Image();
    groupSampleFile.src = this.state.imageDestination;

    this.setState({ customerImg: groupSampleFile });
  }

  componentDidMount() {
    // let frameImageFile = new window.Image();
    // frameImageFile.src = frame;
    // this.setState({ frameImg: frameImageFile });
  }

  getCampaignTemplates(campaignId) {
    this.setState({ isLoading: true });
    this.setState({ loaderMessage: "Loading templaes !!!" });
    let getParams = {
      campaign: campaignId,
    };

    ApiService.getData("getCampaignTemplates", getParams, true).then(
      (response) => {
        console.log(response)
        if (response.status == "success") {
          this.setState({ templates: response.data });
          this.setState({ isLoading: false });
          this.setState({ loaderMessage: "" });
        } else {
          console.log("error");
          // this.props.history.push("/error");
          if (response.message == "Token has expired") {
            StorageService.removeData("access_token");
            StorageService.removeData("campaign_details");
            StorageService.removeData("user_details");
            this.props.history.push(
              "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/login"
            );
          }
        }
      }
    );
  }

  chooseTemplate(
    path,
    id,
    x,
    y,
    width,
    height,
    template_width,
    template_height
  ) {
    let frameImageFile = new window.Image();
    frameImageFile.src = path;
    this.setState({ frameImg: frameImageFile });
    this.setState({ template_id: id });
    this.setState({ template_x: x });
    this.setState({ template_y: y });
    this.setState({ transparent_area_width: width });
    this.setState({ transparent_area_height: height });
    this.setState({ template_width: template_width });
    this.setState({ template_height: template_height });

    this.setState({ info_label_0: "STEP - 2" });
    this.setState({ info_label: "Take a selfie/ Upload image" });
    this.setState({
      info_label_2:
        "Zoomin/Zoomout select and drag the image to postion if needed.",
    });
  }

  generateFrameBase64(path, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", path);
    xhr.responseType = "blob";
    xhr.send();
  }

  triggerChooseTemplateEvent() {
    const div = document.querySelectorAll(".selected div");
    div[0].lastChild.click();
  }

  navigateTemplateCarousel(type) {
    if (type == "next") {
      var elem = document.getElementsByClassName("control-next");
      elem[0].click();
      this.setState({
        current_template_index: this.state.current_template_index + 1,
      });
    } else {
      var elem = document.getElementsByClassName("control-prev");
      elem[0].click();
      this.setState({
        current_template_index: this.state.current_template_index - 1,
      });
    }
  }

  scrollToShare() {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: "shareSection",
      });
    }, 500);
  }

  updateCheckboxVal = () => {
    this.setState({ chkbox: !this.state.chkbox });
  };

  render() {
    return (
      <div>
        <p className="createPostLabel">{this.state.info_label_0}</p>
        <p className="createPostLabel">{this.state.info_label}</p>
        {console.log('Templates',this.state.templates)}
        <p className="createPostLabel">{this.state.info_label_2}</p>

        {this.state.isLoading ? (
          <PostLoader message={this.state.loaderMessage} />
        ) : null}

        {!this.state.isLoading ? (
          <div className="mainOuterSection">
            <div
              className={
                this.state.showPreview
                  ? "img-container hiddenSection"
                  : "img-container"
              }
            >
              {this.state.templates && this.state.frameImg == "" ? (
                <div>
                  <Carousel
                    showThumbs={false}
                    swipeable={false}
                    showIndicators={false}
                    showStatus={false}
                    className="templateCarousel"
                  >
                    {this.state.templates.map((template, index) => (
                      <div key={index}>
                        <img
                          className="templateCarouselImg"
                          src={template.path}
                        />
                        <p
                          className="legend "
                          onClick={() =>
                            this.chooseTemplate(
                              template.path,
                              template.id,
                              template.x,
                              template.y,
                              template.width,
                              template.height,
                              template.template_width,
                              template.template_height
                            )
                          }
                        >
                          Choose this template
                        </p>
                      </div>
                    ))}
                  </Carousel>

                  {this.state.templates.length > 1 ? (
                    <div>
                      {this.state.current_template_index > 1 ? (
                        <img
                          alt="Next"
                          className="prevSlide slideNav"
                          src={require("../../assets/images/prev.png")}
                          onClick={() => this.navigateTemplateCarousel("prev")}
                        />
                      ) : null}
                      {this.state.current_template_index <
                      this.state.templates.length ? (
                        <img
                          alt="Next"
                          className="nextSlide slideNav"
                          src={require("../../assets/images/next.png")}
                          onClick={() => this.navigateTemplateCarousel("next")}
                        />
                      ) : null}
                    </div>
                  ) : null}

                  <Button
                    className="fixedBtn templateBtn"
                    onClick={() => this.triggerChooseTemplateEvent()}
                  >
                    Choose this template
                  </Button>
                </div>
              ) : (
                <div></div>
              )}

              {!this.state.sourceFile && this.state.frameImg ? (
                <div className="upload-btn-wrapper">
                  <div className="uploadBtn">
                    <img
                      alt="Upload"
                      className="uploadImg"
                      src={require("../../assets/images/post/upload.svg")}
                    />
                    <div>Tap to upload your photo</div>
                  </div>
                  <input
                    ref="file"
                    type="file"
                    name="user[image]"
                    multiple="false"
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              ) : null}

              {this.state.sourceFile && this.state.frameImg ? (
                <div>
                  <img
                    ref={this.imageElement}
                    src={this.state.sourceFile}
                    alt="Source"
                    crossOrigin
                    className="sourceImg"
                  />

                  <div className="createBtnOuter">
                    {this.state.isPostCreating ? (
                      <Button className="templateBtn" disabled>
                        Creating your post..
                      </Button>
                    ) : (
                      <Button
                        className="templateBtn"
                        onClick={() => this.viewPreview(true)}
                      >
                        Create Post
                      </Button>
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <Stage
              width={this.state.template_width}
              height={this.state.template_height}
              className={!this.state.showPreview ? "hiddenCanvas" : ""}
              ref={this.stageRef}
              crossOrigin="anonymous"
            >
               <ThemeContext.Provider >
              <Layer id="dummyId">
                <Image
                  image={this.state.customerImg}
                  width={this.state.transparent_area_width}
                  height={this.state.transparent_area_height}
                
                  x={this.state.template_x}
                  y={this.state.template_y}
                />
                <Image
                  image={this.state.frameImg}
                  width={this.state.template_width}
                  height={this.state.template_height}
                />
              </Layer>
              </ThemeContext.Provider>
            </Stage>

            <a
              className={!this.state.showPreview ? "hiddenCanvas" : ""}
              id="download"
              download="myPicture.png"
            ></a>

            {this.state.showPreview &&
            !this.state.isPublished &&
            !this.state.isPublishing ? (
              <div className="checkboxOuter">
                <input
                  type="checkbox"
                  name="terms_check"
                  defaultChecked={this.state.chkbox}
                  onChange={this.updateCheckboxVal}
                  className="checkBoxTerms"
                />
                <label htmlFor="terms_check" className="createPostLabel">
                  {" "}
                  I agree to the{" "}
                  <a onClick={() => this.props.goToPrivacyPage()}>
                    terms & conditions
                  </a>{" "}
                </label>
              </div>
            ) : null}

            {this.state.showPreview && !this.state.isPublished ? (
              <div className="main">
                <div className="publishBtnOuter">
                  {this.state.isPublishing ? (
                    <Button id="publishBtn" className="templateBtn" disabled>
                      Publishing ....
                    </Button>
                  ) : (
                    <Button
                      id="publishBtn"
                      className="templateBtn"
                      onClick={() => this.publishImage()}
                    >
                      Publish
                    </Button>
                  )}
                </div>
              </div>
            ) : null}

            {this.state.isPublished ? (
              <div className="shareSectionOuter" ref={this.shreRef}>
                <p>Download or Share with Friends And family.</p>

                <div className="shareBtnSectionOuter" id="shareSection">
                  {/* <Fab
                    className="shareIconsOuterDiv"
                    onClick={() => this.downloadImage()}
                  >
                    <GetAppIcon />
                  </Fab> */}
                  <Fab
                    className="shareIconsOuterDiv fbshareOuter"
                    onClick={() => this.sharePostToFB()}
                  >
                    <FacebookIcon />
                  </Fab>
                  <Fab
                    className="shareIconsOuterDiv fbshareOuter"
                    onClick={() => this.sharePostToTW()}
                  >
                    <TwitterIcon />
                  </Fab>
                  <Fab
                    className="shareIconsOuterDiv fbshareOuter"
                    onClick={() => this.sharePostToWA()}
                  >
                    <WhatsAppIcon />
                  </Fab>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default ImageCropper;
