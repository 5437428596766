import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import AllCampaignList from "../../components/AllCampaignList";
import LeftDrawer from "../../components/leftDrawer";
import AuthenticationService from "../../services/AuthenticationService";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
const _ = require("lodash");

class AllPosts extends React.Component {
  constructor(props) {
    super(props);

    // AuthenticationService.isCampaignActive(
    //   this.props.match.params["campaign_name"],
    //   this.props.match.params["event_name"]
    // );
  }

  render() {
    let loginPath = "/"+this.props.match.params["event_name"]+"/"+this.props.match.params["campaign_name"]+"/login";
    return (
      <div className="pageOuter">
        <div className="headerGreenBlock">
          <div className="headerOuter">
            {AuthenticationService.isUserLoggedIn() === true ? (
              <LeftDrawer></LeftDrawer>
            ) : (
              <Link to={loginPath}>
                <HomeIcon className="backBtn" />
              </Link>
            )}

            <h4 className="headerTitle">Campaign Wall</h4>
          </div>
        </div>
        <div className="campaignPostBlock">
          <AllCampaignList />
        </div>
      </div>
    );
  }
}
export default withRouter(AllPosts);
