import React from "react";
import "./style.css";
import { AiFillClockCircle } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import ApiService from "../../services/ApiService";
import StorageService from "../../services/StorageService";
import SchoolIcon from '@material-ui/icons/School';
import RoomIcon from '@material-ui/icons/Room';

class ParticipantList extends React.Component {
  constructor(props) {
    super(props);

    if (StorageService.getData("campaign_details")) {
      this.state = {
        participantArr: [],
        totalCount: 0,
        isReady: false,
        campaign_details: JSON.parse(StorageService.getData("campaign_details")),
      };
    } else {
      this.state = {
        participantArr: [],
        totalCount: 0,
        isReady: false,
      };
    }

  }

  componentDidMount() {   
    // console.log('====================================');
    // console.log(JSON.parse(StorageService.getData("campaign_details")));
    // console.log('====================================');
    if (this.state.campaign_details && this.state.campaign_details.length > 0)
      this.getAllParticipants(this.state.campaign_details[0].id);
  }

  getAllParticipants(campaign_id){
    let postParams = {
      campaign_id: campaign_id,
      status:1,
      pageCount:10,
      page:1,
    };

    ApiService.getData("getAllParticipants", postParams, true).then((response) => {    
      if (response.status == "success") {
        this.setState({isReady : true});
        this.setState({participantArr : response.data});
        this.setState({totalCount : response.data.length});
      } else {      
        if (response.message == "Token has expired") {
          StorageService.removeData("access_token");
          StorageService.removeData("campaign_details");
          StorageService.removeData("user_details");
          this.props.history.push(
            "/" +
            this.props.match.params["event_name"] +
            "/" +
            this.props.match.params["campaign_name"] +
            "/login"
          );
        } else {
          this.props.history.push(
            "/" +
            this.props.match.params["event_name"] +
            "/" +
            this.props.match.params["campaign_name"] +
            "/error"
          );
        }
      }
    });
  }

  truncate(str) {
    return str.length > 16 ? str.substring(0, 15) + "..." : str;
  }

  render() {
    return (
      <div>
        {this.state.isReady === true ? (
          <div>
            <h3 className="participantHeading">
              <span className="participantHeadingTxt">Recent Activity</span>
              <span className="participantCount">{this.state.totalCount}</span>
            </h3>
            { this.state.participantArr && this.state.participantArr.length > 0 && this.state.participantArr.map((participant) => (            

              <div className="participantListNew">
                <div className="userProfileImageDiv">
                  <img
                    src={require("../../assets/images/chef-avatar.jpg")}
                    alt="User"
                    className="userProfileImage"
                  />     
                </div>
                <div className="width-80">
                  <div className="nameDiv">
                    <p className="infoTextName">
                        {/* {participant.name} */}
                        {this.truncate(participant.name)}
                    </p>
                  </div>
                  <div className="ageDiv">
                    <p className="infoTextRight">
                      ( {participant.age} yrs )
                    </p>
                  </div>
                </div>
                <div className="width-80">
                  <div className="districtDiv">
                    <p className="infoText">
                        <SchoolIcon className="infoIcons"/> 
                        {/* {participant.school_name} */}
                        {this.truncate(participant.school_name)}
                    </p>
                  </div>
                  <div className="schoolDiv">
                    <p className="infoTextRight">
                       {/* <RoomIcon className="infoIcons"/>  */}
                       {participant.district} 
                    </p>
                  </div>
                </div>           
              </div>

            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
export default ParticipantList;
