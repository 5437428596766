import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import CampaignList from "../../components/CampaignList";
import StorageService from "../../services/StorageService";
import LeftDrawer from "../../components/leftDrawer";
import AuthenticationService from "../../services/AuthenticationService";
import {S3_BUCKET_URL, IMAGE_CONSOLE_EVENT_ID} from '../../config/constants'
import ApiService from "../../services/ApiService";
import ImageGallery from "../../components/MasonryListing";
import DownloadPostButton from "../../components/DownloadPostButton";
import {
  BsPencilSquare,
} from "react-icons/bs";
import {
  AiOutlineCloseCircle,
} from "react-icons/ai";
import ProfileImage from '../FaceRecognitionSearch/uploadImage';
import S3 from "../../services/aws/s3";
import urlConfig from "../../config/url-config";

const _ = require("lodash");
const search = window.location.search;
const params = new URLSearchParams(search);
const app_type = params.get("app_type");
const event = params.get("event");

class Profile extends React.Component {


  username = "";
  email = "";
  userDetails = "";
  constructor(props) {
    super(props);

    if (!app_type || app_type != "photo-app") {
      AuthenticationService.isCampaignActive(
        this.props.match.params["campaign_name"],
        this.props.match.params["event_name"]
      );
    }

    this.state = {
      user_details: JSON.parse(StorageService.getData("user_details")),
      isLoading:true,
      frEventId: StorageService.getData("fr_event_id") ? StorageService.getData("fr_event_id") : IMAGE_CONSOLE_EVENT_ID,
      imageList:[],
      selectedImages:[],
      isImageEdit:false,
      isLoading:false,
      bg:'#fff',
    };

    if (StorageService.getData("user_details"))
      this.userDetails = JSON.parse(StorageService.getData("user_details"));

    if (this.userDetails) {
      if (this.userDetails.firstName)
        this.username =
          this.userDetails.firstName + " " + this.userDetails.lastName;
      // if (this.userDetails.email) email = this.userDetails.email;
    }
  }

  componentDidMount(){
    this.getFRImages();
  }
  getFRImages(){
    ApiService.frGetData(`fr/search-results?event_id=${this.state.frEventId}&participant_id=${this.state.user_details.id}`, {}, true).then(
      (response) => {
        if(response){
          if(response[this.state.user_details.id].matches){
            let images = Object.values(response[this.state.user_details.id].matches);
            console.log('Response', typeof images);
           let processedImages = images.map(item=>Object.keys(item)[0]);
          this.setState({imageList:processedImages})
          }
        }
      
      }).catch(err=>{})
  }


  saveImagesToProfile(){
    let params = {
      images: JSON.stringify(this.state.selectedImages),
    };
    ApiService.postData("uploadImages", params, true).then(
      (response) => {
        console.log(response)
        if (response.status == "success") {
          this.props.history.push("/my-downloads?app_type=photo-app");
        } else {
          alert('Something went wrong. Please try again later');
        }
      }
    );
  }

  submitParticipation() {
    this.props.history.push("/post");
  }

  /*****Upload Profile Image */
  uploadImage(image){  
    const type = image.split(';')[0].split('/')[1];  
    this.setState({isLoading:true});          
    let timestamp = + new Date();     
    S3.upload(image,timestamp+'.'+type,(data)=>{
      this.saveProfileLink(data.Location);
    });
}

saveProfileLink(link){
  console.log(link);
    let user =  this.state.user_details;
    let postParams = {
        profile_image:link,
    };
    ApiService.postData("updateProfileImage", postParams, true).then(
        (response) => {
            if (response.status == "success") {
                let newData = {
                    ...user,
                    profile_image:link,
                };
                StorageService.storeData("user_details", JSON.stringify(newData));
                this.setState({isLoading:false, userDetails:newData});
                // this.postSelfie(link);
                this.setState({isImageEdit:false})
            }
        }).catch(e=>{
            this.setState({isLoading:false});
        })
}
 /**
     * 
     * @param {*Link AWS URL} link
     * Post image to FR API 
     * 
     */
 postSelfie(link)
 {
     let user =  this.state.user_details;
     let profileImage = link.replace(urlConfig.s3Config('bucket_url'),'');
     let postParams = {
         customer_image:decodeURI(profileImage),
         customer_id:user.id,
         event_id: this.state.frEventId,
         image_type:'branded',

     }

     ApiService.frPostData("fr/search-face-matches", postParams, true).then(
         (response) => {
             console.log(response);
         }).catch(e=>{})
 }
  /*****Upload Profile Image End */

  render() {
    return (
      <div className="pageOuter" style={{background:this.state.bg}}>
        <div className="profileGreenBlock">
          <div className="headerOuter">
            <LeftDrawer></LeftDrawer>
            <h4 className="headerTitle"></h4>
          </div>
          <div className="profileOuter profile-wrapper" >
            <div style={{position:'relative'}} onClick={()=>this.setState(prevState =>({isImageEdit:!prevState.isImageEdit}))}>
            <img className="user-profile-image" src={this.state.user_details.profile_image}></img>
            {this.state.isImageEdit &&
            
            <AiOutlineCloseCircle className="edit-icon"/> ||
            <BsPencilSquare className="edit-icon"/>
              }
            </div>
            <div>
              <h4 className="profileTitleWelcome">Welcome</h4>
                <h3 className="profileTitle">
                  <span id="username">
                    {this.state.user_details.firstName}{" "}
                    {/* {this.state.user_details.lastName}{" "} */}
                  </span>
              </h3>
            </div>
          </div>          
        </div>

        {
           (app_type == "photo-app" && this.state.isImageEdit)  &&
          <div style={{paddingLeft:20,paddingRight:20}}>
           <ProfileImage onCamChange={(value)=>{this.setState({bg:value ? '#000' : '#fff'})}} isLoading={this.state.isLoading} uploadImage={(image) => this.uploadImage(image)}/>
           </div>
        }
        {
          (app_type == "photo-app" && !this.state.isImageEdit)  &&
          
          (
            this.state.imageList <= 0 &&
          <div className="page-wrapper">
            {/* <p style={{paddingTop:200}}>Your images are pending approval.</p><p>All of your images will appear here.</p> */}
          </div> ||
          <>
              <ImageGallery
              onSelect={(image, isSelected)=>{
              if(isSelected) {
                  this.setState({selectedImages:[...this.state.selectedImages,image]});
              } else {
                  let selectedImages = this.state.selectedImages.filter(item => item!=image);
                  this.setState({selectedImages});
              }              
              }}
          images={this.state.imageList}
      /> 
       {this.state.selectedImages.length > 0 &&
        <DownloadPostButton
                count={this.state.selectedImages.length}
                type='btn_save'
                onClickDownloadButton={()=>this.saveImagesToProfile()}
                endText={"Save Images"}
                />
          }
      </>
          )
        }

        {(!app_type || app_type != "photo-app") && (
          <div className="campaignPostBlock">
            <CampaignList />
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Profile);
