import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";

import ApiService from "../../services/ApiService";
import { any } from "prop-types";
import { Link } from "react-router-dom";

import Fab from "@material-ui/core/Fab";
import GetAppIcon from "@material-ui/icons/GetApp";

class CampaignList extends React.Component {
  constructor() {
    super();

    this.state = {
      campaings: [],
      isReady: false,
      totalCamps: 0,
      campaign_details: JSON.parse(StorageService.getData("campaign_details")),
    };
  }

  componentDidMount() {
    this.getUserCampaigns();
  }

  getUserCampaigns() {
    let getParams = {
      campaign_id: this.state.campaign_details[0].id,
    };
    ApiService.getData("getUserPosts", getParams, true).then((response) => {
      if (response.status == "success") {
        this.setState({ campaings: response.data.data });
        this.setState({ totalCamps: response.data.total });
        this.setState({ isReady: true });
      } else {
        console.log("error");
        if(response.message == "Token has expired"){
          StorageService.removeData("access_token");
          StorageService.removeData("campaign_details");
          StorageService.removeData("user_details");
          this.props.history.push(
            "/" +
            this.props.match.params["event_name"] +
            "/" +
            this.props.match.params["campaign_name"] +
            "/login"
          );
        }else{
          this.props.history.push(
            "/" +
            this.props.match.params["event_name"] +
            "/" +
            this.props.match.params["campaign_name"] +
            "/error"
          );
        }
      }
    });
  }

  downloadImage(path) {
    var download = document.getElementById("download");  

    var image = path;
    download.setAttribute("href", image);
    download.click();
  }

  render() {
    return (
      <div className="campaigns-items">
        {this.state.isReady === true ? (
          <div>
            <h3 className="campaignPostHeading">
              <span className="campaignHeadingTxt">Posts</span>
              <span className="campaignPostCount">{this.state.totalCamps}</span>
            </h3>
            {this.state.campaings.map((camp) => (
              <div class="item">
                <img src={camp.image_path} />
                  <a
                    className="downloadFb"
                    onClick={() => this.downloadImage(camp.base64_path)} 
                  >
                    <img
                      src={require("./../../assets/images/export.svg")}
                      alt="Export Post"
                      className="exportIcon"
                    />   
                  </a>
                <a
                  className="hiddenCanvas"
                  id="download"
                  target="_blank" rel="noopener noreferrer" download
                ></a>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(CampaignList);
