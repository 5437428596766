import React from "react";
import "./style.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import ParticipantCard from "../../components/ParticipantCard";
import ParticipantCard2 from "../../components/ParticipantCard2";
import Divider from "@material-ui/core/Divider";
import StorageService from "../../services/StorageService";
import { Constants } from "../../config";
import ApiService from "../../services/ApiService";
import loader from "../../assets/images/loader.gif";
import loaderNormal from "../../assets/images/loader-normal.gif";

const __DEV__ = Constants.__DEV__;
const SERVERPATH = Constants.SERVERPATH;
const imgPath = Constants.IMAGE_PATH;

class CustomerRegistrationPayment extends React.Component {
  constructor(props) {
    super(props);

    let arr = JSON.parse(StorageService.getData("participant_arr"));
    let user_details = JSON.parse(StorageService.getData("user_details"));

    this.state = {
      ticket_count: arr ? arr.length : 0,
      // ticket_count: StorageService.getData('ticket_count'),
      current_entry_no: 1,
      participantArr: arr && arr.length > 0 ? arr : [],
      participantCount: arr && arr.length > 0 ? true : false,
      ticket_price: Constants.TICKET_PRICE,
      total_amount: 0,
      user_name: user_details["firstName"] + " " + user_details["lastName"],
      user_email: user_details["email"],
      campaign_details: JSON.parse(StorageService.getData("campaign_details")),
      is_loading: true,
      participantIds: [],
      user_order_id: "",
      toSuccessPage: false,
      loader_message: "",
    };
  }

  componentDidMount() {
    if (this.state.participantArr.length < 1) {
      this.props.history.push(
        "/" +
          this.props.match.params["event_name"] +
          "/" +
          this.props.match.params["campaign_name"] +
          "/home"
      );
    } else {
      const orderSummary = {
        total_entries: this.state.ticket_count,
        unit_price: Constants.TICKET_PRICE,
        total_amount: this.state.total_amount,
        status: 1,
      };
      this.saveEnrollmentDetails(this.state.participantArr, orderSummary);
      let amount = this.calculateTotalAmount();
      this.setState({ total_amount: amount });
    }
  }

  calculateTotalAmount() {
    let totalAmount = this.state.ticket_count * this.state.ticket_price;
    return totalAmount;
  }

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async displayRazorpay() {
    this.setState({
      loader_message: "Please wait while we intiate the payment procedure",
    });
    this.setState({ is_loading: true });

    const stateVal = this.state;

    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let postParams = {
      ticket_count: this.state.ticket_count,
    };

    const data = await fetch(SERVERPATH + "/razorpay", {
      method: "POST",
      body: JSON.stringify(postParams),
      headers: { "Content-Type": "application/json" },
    }).then((t) => t.json());

    const options = {
      // key: __DEV__ ? Constants.RAZORPAY_KEY_DEV : Constants.RAZORPAY_KEY_PROD,
      key: Constants.RAZORPAY_KEY,
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: this.state.campaign_details[0]["title"],
      description: "Thank you for enrolling",
      image: this.state.campaign_details[0]["logo"],
      handler: (response) => {
        if (response) {
          this.setState({
            loader_message: "Please wait while we enroll the participants",
          });

          const paymentDetails = {
            amount: data.amount.toString(),
            gateway: "razorpay",
            transaction_id: response.razorpay_signature,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            gateway_response: response,
            status: 1,
          };

          let postParams = {
            payment_detail: paymentDetails,
            participants: stateVal.participantIds,
            user_order_id: stateVal.user_order_id,
          };
          ApiService.postData(
            "updateParticipantEnrolmentPayment",
            postParams,
            true
          ).then((response) => {
            if (response.status == "success") {
              StorageService.removeData("participant_arr");
              StorageService.removeData("ticket_count");
              this.setState({ is_loading: false });
              this.props.history.push(
                "/" +
                  this.props.match.params["event_name"] +
                  "/" +
                  this.props.match.params["campaign_name"] +
                  "/registration-success"
              );
            } else {
            }
          });
        }
      },
      modal: {
        ondismiss: () => {
          alert("Payment cancelled. Try again");
          this.setState({ is_loading: false });
        },
      },
      prefill: {
        name: this.state.user_name,
        email: this.state.user_email,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  goToSuccessPage() {
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/registration-success"
    );
  }

  goBack() {
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/registration"
    );
  }

  saveEnrollmentDetails = (participants, orderSummary) => {
    let postParams = {
      participants: JSON.stringify(participants),
      order_summary: JSON.stringify(orderSummary),
      campaign_id: this.state.campaign_details[0]["id"],
    };
    ApiService.postData("createParticipantEnrollment", postParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({ is_loading: false });
          this.setState({ participantIds: response.data.participantIds });
          this.setState({ user_order_id: response.data.userOrderId });
        } else {
          this.setState({ is_loading: false });
          this.props.history.push(
            "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/error"
          );

          this.setState({ loader_message: "" });
        }
      }
    );
  };

  render() {
    if (this.state.is_loading) {
      return (
        <div className="loaderOuter">
          <img
            src={this.state.loader_message != "" ? loaderNormal : loader}
            alt="loading..."
            className="loaderImage"
            style={
              this.state.loader_message == ""
                ? { width: "80%", marginTop: "40%" }
                : { marginTop: "60%" }
            }
          />
          <p className="loaderText">
            {this.state.loader_message != ""
              ? this.state.loader_message
              : "Please wait while we set everything"}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          {this.state.participantCount && (
            <div id="cust_reg_container" className="main-container">
              <div id="header">
                <nav id="back" onClick={() => this.goBack()}></nav>
                <h1 id="page-title">Complete registration</h1>
              </div>

              <div id="content-area">
                <h4 id="page-subTitle">Participants</h4>
                <div className="participants">
                  {this.state.participantArr.map((item, key) => (
                    <ParticipantCard2 data={item} key={key} />
                  ))}
                </div>

                <div className="payment-summary">
                  <h4 id="page-subTitle">Payment summary</h4>

                  <div className="total_entries row">
                    <label className="label">Total Entries</label>
                    <label className="value">{this.state.ticket_count}</label>
                  </div>

                  <div className="ticket_cost row">
                    <label className="label">Ticket Cost </label>
                    <label className="value">
                      <span>&#x20B9;</span> {this.state.ticket_price}
                    </label>
                  </div>

                  <div className="amount row">
                    <label className="label">Total Amount </label>
                    <label className="value">
                      <span>&#x20B9;</span> {this.state.total_amount}
                    </label>
                  </div>
                </div>
              </div>

              <div id="footer">
                {this.state.participantIds.length > 0 &&
                this.state.user_order_id != "" ? (
                  <Button
                    className="btn-done"
                    size="large"
                    onClick={() => this.displayRazorpay()}
                  >
                    Pay ₹ {this.state.total_amount}
                  </Button>
                ) : (
                  <Button className="btn-done-disabled" size="large">
                    Pay ₹ {this.state.total_amount}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}
export default withRouter(CustomerRegistrationPayment);
