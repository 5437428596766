import React from "react";
import "./style.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";
import AuthenticationService from "../../services/AuthenticationService";
import ApiService from "../../services/ApiService";
import ParticipantList2 from "../../components/ParticipantList2";
import ParticipantCard2 from "../../components/ParticipantCard2";
import loaderNormal from '../../assets/images/loader-normal.gif';
import Divider from '@material-ui/core/Divider';

class CampaignParticipants extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      participantArr: [],
      campaign_details: JSON.parse(StorageService.getData('campaign_details')),
      is_loading: false
    };

  }

  async componentDidMount() {
    await this.getUserParticipantsList();
  }

  viewEnrollmentTicketPage() {
    this.props.history.push(
      "/" +
      this.props.match.params["event_name"] +
      "/" +
      this.props.match.params["campaign_name"] +
      "/registration-ticket"
    );
  }

  goBack() {
    this.props.history.push(
      "/" +
      this.props.match.params["event_name"] +
      "/" +
      this.props.match.params["campaign_name"] +
      "/home"
    );
  }

  async getUserParticipantsList() {
    this.setState({ is_loading: true });
    let postParams = {
      campaign_id: this.state.campaign_details[0].id,
    };

    ApiService.getData("getUserParticipants", postParams, true).then((response) => {
      if (response.status == "success") {
        this.setState({ participantArr: response.data });
        this.setState({ is_loading: false });
      } else {

      }
    });
  }



  render() {

    if (this.state.is_loading) {

      return (
        <div className="loaderOuter">
          <img src={loaderNormal} alt="loading..." className="loaderImage" />
          <p className="loaderText">Please while we fetch the participants</p>
        </div>
      );

    } else {
      return (
        <div className="main-container">
          <div id="header">
            <nav id="back" onClick={() => this.goBack()}></nav>
            <h1 id="page-title">Participants</h1>
          </div>

          <div id="participant-content-area">

            {(this.state.participantArr.length > 0) && this.state.participantArr.map((item, key) => (
              <ParticipantList2
                data={item}
                key={key}
              />
            ))}
            <Divider />
            {/* {(this.state.participantArr.length > 0) && this.state.participantArr.map((item, key) => (
              <ParticipantCard2
                data={item}
                key={key}
                noAction={true}
              />
            ))} */}
          </div>

          <div id="footer">
            <Button
              className="btn-done"
              size="large"
              onClick={() => this.viewEnrollmentTicketPage()}
            >
              Add more
            </Button>
          </div>
        </div>
      );
    }

  }
}
export default withRouter(CampaignParticipants);
