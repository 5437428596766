import React from "react";
import './style.css'
import Header from '../../../components/Conference/Header'
import { withRouter } from "react-router";
import ReactPlayer from 'react-player/lazy'
import Modal from 'react-modal';
import { Button } from "@material-ui/core";
import Masonry from "react-responsive-masonry"
import VideoImageThumbnail from 'react-video-thumbnail-image'; 
import { FiPlayCircle } from "react-icons/fi";
import awsS3 from "../../../services/aws/s3";
import {AWS_S3_URL} from '../../../config/constants'
import StorageService from "../../../services/StorageService";


const VideoBox =({item,index,onClick})=>{
  let videoUrl = AWS_S3_URL+''+item.Key;
  if(item.Size < 1) return null;
    return(
        <div onClick={()=>onClick(videoUrl)} className="video-box" key={index} >         
        <VideoImageThumbnail
        key={index}
        videoUrl={videoUrl}                 
        cors={true}             
    />
     <div className="user-icon-wraper">
        <FiPlayCircle color={'#fff'} size="40"/>
        </div> 
    </div>
    )
}


const VideoListing = ({onClick,videos})=>{
    return(
        <div className="list-group list-group-vertical">
              <Masonry columnsCount={2} gutter={'10px'}>
            {videos.map((item,index)=>(
                <VideoBox onClick={onClick} index={index} key={index} item={item}/>

            ))}
            </Masonry>
        </div>
    )
}


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

const Player =({show,onClose,file})=>{
    return(
        <Modal
        isOpen={show}       
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Button style={{right:20,position:'absolute'}} onClick={onClose}>
        <p >Close</p>
        </Button>
        <ReactPlayer    
    width={'100%'}
    url={file}
   controls={true}
    />
    </Modal>
    )
}


class VideoGallery extends React.Component {
state ={
    show:false,
    video:null,
    s3Videos:[],
    isLoading:true,
}


componentDidMount(){
    this.getVideos()
}
getVideos(){
    let user =  JSON.parse(StorageService.getData("user_details"));
    console.log(user);
    console.log(`user-videos/${user.phone}`);
    awsS3.getObjectData(`user-videos/${user.phone}/`,(data)=>{
        console.log(data);
        this.setState({s3Videos:data,isLoading:false});
     });
}

render(){
    
    return(
        <div className="video-gallery">
            <Header />
                <div className="tab-bar">
                    <div className="active">
                        <p >Video Gallery</p>
                    </div>
                    
                </div>
                <Player file={this.state.video} show={this.state.show} onClose={()=>this.setState({show:false})}/>
             {
                !this.state.isLoading  &&
                    this.state.s3Videos.length > 0 &&
                <VideoListing videos={this.state.s3Videos} onClick={(item)=>this.setState({video:item,show:true})}/> ||
                <div className="empty-state"><p>Event videos will list here</p></div>
             }
                           
        </div>
    )
}
}


export default withRouter(VideoGallery);
